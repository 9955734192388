import React from "react";

export default function Phase3() {
  return (
    <>
      {" "}
      <div className="displayCenter">
        <h1 style={{ fontSize: "30px", position: "absolute", top: "45%" }}>
          Coming Soon
        </h1>
      </div>
    </>
  );
}
