import React, { useState, useEffect, useContext } from "react";
import { Button } from "react95";
import axios from "axios";
import ApiConfig from "src/config/APIConfig";
import moment from "moment";
import BuyModal from "./BuyModal";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";

export default function FeaturedList({ searchToken, select, setendTime }) {
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [inscription, setInscription] = useState("");
  const [index, setindex] = useState(0);
  const [isLoading1, setIsLoading1] = useState(false); // eslint-disable-line
  const accessToken = window.sessionStorage.getItem("token");
  const VoteApiHandler = async (id) => {
    try {
      setIsLoading1(true);
      const result = await axios({
        url: `${ApiConfig.addVotes}/${id}`,
        method: "get",
        // data: { _id: rowId },
        headers: {
          token: accessToken,
        },
      });
      auth.GetFeatureListAPI();
      if (result.data.response_code === 200) {
        auth.getProfileData(sessionStorage.getItem("token"));
        console.log("res", result.data);
        setIsLoading1(false);
        toast.success(result.data.response_message);
      } else {
        toast.error(result.data.response_message);
      }
      setIsLoading1(false);
    } catch (err) {
      console.log("err", err);
      auth.setAssets([]);
      setIsLoading1(false);
      window.sessionStorage.removeItem("token");
    }
  };
  useEffect(() => {
    auth.GetFeatureListAPI();
  }, []); // eslint-disable-line

  return (
    <>
      {" "}
      <div className="contentgrid">
        {auth.Assets &&
          auth.Assets.map((item, index) => {
            let bookedSlot;
            if (item?.bookedSlot?.length > 0) {
              let bookedSlotLen = item?.bookedSlot;
              // bookedSlot = item?.bookedSlot;
              for (let i = 0; i < bookedSlotLen?.length; i++) {
                // eslint-disable-next-line
                // console.log(
                //   "---bookedSlot?.totalVotes---------61",
                //   bookedSlotLen
                // );
                if (!bookedSlotLen[i].isExpired) {
                  bookedSlot = bookedSlotLen[i];
                }
                if (
                  bookedSlotLen[i].isExpired &&
                  item.title == bookedSlotLen[i].title
                ) {
                  bookedSlot = bookedSlotLen[i];
                }
                // if (
                //   moment(bookedSlotLen[i].endDate).unix() > moment().unix() &&
                //   moment().unix() > moment(bookedSlotLen[i].startDate).unix()
                // ) {
                //   bookedSlot = bookedSlotLen[i];
                // }
              } // eslint-disable-next-line
              if (index == 0) {
                console.log("---bookedSlot?.totalVotes------- 80", bookedSlot);
                // console.log(bookedSlot, "--------------bookedSlot---");
              }
              if (item?.inscriptionNumber == "#50715") {
                console.log("---bookedSlot?.totalVotes------- 84", item);
                // console.log(bookedSlot, "--------------bookedSlot---");
              }
            }

            return (
              <div className="collectionBox">
                <div className="contentBox dFlex">
                  <h1 style={{ fontSize: "20px" }}>
                    {/* {item?.inscriptionNumber} */}
                    {bookedSlot ? bookedSlot?.title : item?.title}
                  </h1>

                  <div className="imageSection">
                    {bookedSlot
                      ? bookedSlot?.totalVotes
                      : item?.totalVotes
                      ? item?.totalVotes
                      : 0}
                    {/* {bookedSlot ? bookedSlot?.totalVotes : item?.totalVotes}{" "} */}
                    &nbsp;{" "}
                    {bookedSlot ? (
                      bookedSlot?.isVote ? (
                        <>
                          <img
                            src={"/images/upport.png"}
                            alt="CollectionImage"
                            className="featureImg1"
                            style={{
                              filter: "hue-rotate(180deg)",
                              cursor: "not-allowed",
                            }}
                          />
                        </>
                      ) : (
                        <img
                          src={"/images/upport.png"}
                          alt="CollectionImage"
                          className="featureImg1"
                          onClick={() => {
                            if (!accessToken) {
                              select("btcWallet");
                            } else {
                              VoteApiHandler(
                                bookedSlot ? bookedSlot?._id : item?.buyAddId
                              );
                            }
                          }}
                        />
                      )
                    ) : item?.isVote ? (
                      <>
                        <img
                          src={"/images/upport.png"}
                          alt="CollectionImage"
                          className="featureImg1"
                          style={{
                            filter: "hue-rotate(180deg)",
                            cursor: "not-allowed",
                          }}
                        />
                      </>
                    ) : (
                      <img
                        src={"/images/upport.png"}
                        alt="CollectionImage"
                        className="featureImg1"
                        onClick={() => {
                          if (!accessToken) {
                            select("btcWallet");
                          } else {
                            VoteApiHandler(
                              bookedSlot ? bookedSlot?._id : item?.buyAddId
                            );
                          }
                        }}
                      />
                    )}
                    {/* {bookedSlot ? (
                      bookedSlot?.totalVotes ? (
                        bookedSlot?.isVote
                      ) : item?.isVote ? (
                        <>
                          <img
                            src={"/images/upport.png"}
                            alt="CollectionImage"
                            className="featureImg1"
                            style={{
                              filter: "hue-rotate(180deg)",
                              cursor: "not-allowed",
                            }}
                          />
                        </>
                      ) : (
                        <img
                          src={"/images/upport.png"}
                          alt="CollectionImage"
                          className="featureImg1"
                          onClick={() => {
                            if (!accessToken) {
                              select("btcWallet");
                            } else {
                              VoteApiHandler(
                                bookedSlot ? bookedSlot?._id : item?.buyAddId
                              );
                            }
                          }}
                        />
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="imageSection">
                  <img
                    src={bookedSlot ? bookedSlot?.url : item?.url}
                    alt="CollectionImage"
                    className="featureImg"
                    onClick={() => window.open(item?.clickTags)}
                  />
                </div>

                <div className="contentBox">
                  <div className="dFlex">
                    <h1 style={{ fontSize: "20px" }}>
                      {item?.inscriptionNumber}
                      {/* {bookedSlot ? bookedSlot?.title : item?.title} */}
                    </h1>
                  </div>
                  <div
                    style={{ marginTop: "7px", alignItems: "flex-start" }}
                    className="dFlex"
                  >
                    <div className="">
                      <Button
                        style={{ marginRight: "5px", marginBottom: "5px" }}
                        onClick={() => {
                          if (bookedSlot) {
                            window.open(bookedSlot?.clickTags);
                          } else {
                            window.open(item?.clickTags);
                          }
                        }}
                      >
                        Project Link
                      </Button>
                      <Button
                        style={{ marginRight: "5px", marginBottom: "5px" }}
                        onClick={() => {
                          // select("btcWallet");
                          select("infoBox");
                          auth.setProjectInfo(bookedSlot ? bookedSlot : item);
                        }}
                      >
                        Project Info
                      </Button>
                    </div>

                    {item?.bookedSlot?.length === 3 ? (
                      <Button
                        style={{
                          marginRight: "5px",
                          marginBottom: "5px",
                          cursor: "auto",
                        }}
                        // disabled
                        type="text"
                      >
                        <span style={{ color: "#000 !important" }}>
                          Slot sold out
                        </span>
                      </Button>
                    ) : (
                      <Button
                        style={{ marginRight: "5px", marginBottom: "5px" }}
                        // onClick={() => window.open(item.clickTags)}
                        disabled={item?.bookedSlot?.length === 3}
                        onClick={() => {
                          if (!accessToken) {
                            select("btcWallet");
                          } else {
                            setOpen(true);
                            setInscription(item);
                            setindex(index);
                          }
                        }}
                      >
                        {item?.bookedSlot?.length === 3 ? (
                          <span>Slot sold out</span>
                        ) : (
                          "Get Slot"
                        )}
                      </Button>
                    )}

                    {/* <Button
                    disabled
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  >
                    Game
                  </Button>
                  <Button disabled style={{ marginBottom: "5px" }}>
                    Buy Listing
                  </Button> */}
                  </div>
                </div>
              </div>
            );
          })}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {
            // eslint-disable-next-line
            !auth.isLoading && auth.Assets?.length == 0 && <p>No Data Found</p>
          }
          {auth.isLoading && (
            <div>
              <img
                src="images/buttonLoading.gif"
                alt="whitepaper"
                style={{ width: "80px" }}
              />
            </div>
          )}
        </div>
      </div>
      {open && (
        <BuyModal
          inscription={inscription}
          activeBuy={open}
          close={() => setOpen(false)}
          GetFeatureListAPI={auth.GetFeatureListAPI}
          index={index}
        />
      )}
    </>
  );
}
